<template>
  <div>
    <div class="custom-container">
      <section class="brand">
        <div id="bg">
          <img :src="bgImg" class="loginImg" />
        </div>
      </section>
      <section class="auth">
        <template>
          <div class="w-full form-cnt min-h-full">
            <form @submit.prevent="submitForm" class="pr-10">
              <div class="flex flex-col gap-8 pr-0 pl-8 ml-4">
                <div v-if="loginInfo" :style="{ paddingBottom: '6px' }">
                  <img
                    v-if="loginScreenInfo.logo_url"
                    :src="loginScreenInfo.logo_url"
                    class="custom_logo"
                  />
                  <div class="tagline_cnt">
                    <span class="tagline">{{ loginScreenInfo.tagline }}</span>
                  </div>
                </div>
                <div>
                  <p class="block mb-2 text-black text-2xl font-semibold">
                    Login to your account
                  </p>
                </div>
                <div class="flex flex-col gap-4">
                  <div class="relative">
                    <label
                      for="Email"
                      class="block mb-2 text-sm font-normal text-gray-900 dark:text-white"
                      >Email</label
                    >
                    <div class="flex items-center relative">
                      <input
                        type="text"
                        id="Email"
                        v-model="formData.email"
                        class="bg-gray-50 border border-gray-300 gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
                        :class="
                          showError && !isEmailValid
                            ? 'border-red-600'
                            : 'border-gray-300'
                        "
                        placeholder="Enter Email"
                        disabled
                        @change="disableError"
                      />
                    </div>
                    <span
                      :class="{ hideError: !(showError && !isEmailValid) }"
                      class="text-red-600 text-xs error"
                      >Invalid Email</span
                    >
                  </div>
                  <div v-if="showOrganizationSelection">
                    <label
                      for="email"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white-text"
                      >Select your organization</label
                    >
                    <dropdown-base
                      :defaultHeightAndRound="false"
                      maxWidth="100%"
                      placeholder="Select Account"
                      :searchable="true"
                      width="100px"
                      minWidth="100%"
                      :options="organizationOption"
                      :config="{ label: 'name', trackBy: 'id' }"
                      :selectedClient="selectedOrganization"
                      :style="`height: 50px; width: 300px`"
                      :disabled="organizationOptionDisabled"
                      :value="selectedOrganization"
                      @select="handleOrganizationSelection($event)"
                    />
                  </div>
                  <div class="relative" v-if="showPasswordField">
                    <label
                      for="password"
                      class="block mb-2 text-sm font-normal text-gray-900 dark:text-white"
                      >Password</label
                    >
                    <div class="flex items-center relative">
                      <input
                        type="password"
                        ref="passRef"
                        id="password"
                        v-model="formData.password"
                        autocomplete="new-password"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
                        placeholder="Enter Password"
                        @blur="handleInputBlur"
                      />
                      <EyeClosed
                        class="absolute right-4 cursor-pointer "
                        @click="togglePass"
                        v-if="!isPassVisible"
                      />
                      <Eye
                        class="absolute right-4 cursor-pointer "
                        @click="togglePass"
                        v-else
                      />
                    </div>
                    <span
                      :class="{
                        hideError: !(
                          showError &&
                          !isPassValid &&
                          formData.password
                        ),
                      }"
                      class="text-red-600 text-xs error"
                      >Should be a minimum of 12 characters</span
                    >
                    <div class="flex justify-end items-center" v-if="true">
                      <div
                        @click="handleForgotPassword"
                        class="text-primary text-xs mt-2 cursor-pointer no-underline forget-pass"
                      >
                        Forgot Password?
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col gap-4">
                  <button
                    class="form-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-10 cursor-pointer login-button"
                    :class="{
                      btnDisable:
                        !isFormFilled ||
                        disabled ||
                        this.selectedOrganization === null,
                    }"
                    type="submit"
                    :disabled="
                      !isFormFilled ||
                        disabled ||
                        this.selectedOrganization === null
                    "
                  >
                    <Loader v-if="loading" class="h-6 w-6" :size="6"></Loader>
                    {{ !loading ? submitText : "" }}
                    <!-- {{ !disabled && !loginViaPassword ? "Login" : loginViaPassword ? "Login by password" : organizationSelection ? "Next" : "" }} -->
                  </button>
                  <!-- <div
                    class="flex justify-center items-center powered_by_cnt"
                    v-if="loginScreenInfo.powered_by && loginInfo"
                  >
                    <span class="poweredBy">Powered by</span>
                    <img :src="Logo1" class="poweredBy_logo" />
                  </div> -->
                </div>
                <div v-if="loginViaSso && !showPasswordField">
                  <div class="flex items-center justify-center py-1">
                    <div class="flex-grow border-t-2 border-gray-300"></div>
                    <span class="mx-4">Login via</span>
                    <div class="flex-grow border-t-2 border-gray-300"></div>
                  </div>
                  <div
                    class="flex items-center justify-center py-4 gap-4 flex-wrap"
                  >
                    <div v-for="item in ssoList" :key="item.id">
                      <button
                        @click="handleSsoLogin(item)"
                        type="button"
                        class="sso-button h-8 w-32 justify-center text-brand bg-white hover:bg-primary-100 font-semibold rounded-md text-xs  text-center inline-flex items-center disabled:opacity-70 border-brand border-2"
                      >
                        <div>{{ item.display_name }}</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>
<script>
import { LoginImg, Logo1, bgImg } from "@/plugins/common.js";
import Eye from "@/assets/svg/eye.svg";
import EyeClosed from "@/assets/svg/closeeye.svg";
// verifyLoginEmail
import { getLoginInfo, submitPasswordForLogin, sendOtp } from "./services";
import Loader from "@shared/loader";
import sections from "./store";
import DropdownBase from "@shared/components/dropdown-base";
import { mapActions, mapGetters } from "vuex";
import VueCookies from "vue-cookies";

export default {
  components: {
    Loader,
    DropdownBase,
    Eye,
    EyeClosed,
  },
  data() {
    return {
      LoginImg,
      Logo1,
      bgImg,
      // defaultLogo,
      disabled: false,
      requestId: null,
      formData: {
        email: "",
        password: "",
      },
      showError: false,
      isEmailFilled: false,
      isPassVisible: false,
      loginInfo: false,
      organizationOption: [],
      selectedOrganization: null,
      ssoList: [],
      loginViaPassword: false,
      loginViaSso: false,
      organizationOptionDisabled: false,
      organizationSelection: false,
      submitText: "Next",
      showPasswordField: false,
      loginViaSingleSso: false,
      loading: false,
    };
  },
  async mounted() {
    await getLoginInfo();
    this.loginInfo = true;

    if (this.getUnifiedPortalState) {
      this.formData.email = this.getUnifiedPortalState.email;
      this.isEmailFilled = true;
    }
    // if (this.$route?.query?.email ) {
    //   this.formData.email = this.$route?.query?.email;
    //   this.isEmailFilled = true;
    // }

    // comes from login - otp verify - unified portal (need to call handleGlobalAuthData)
    if (!this.getLoginViaPassword) {
      this.handleGlobalAuthData();
    } else {
      // if user comes from login via password -> otp verify -> unified portal
      // (no need to call handleGlobalAuthData)
      this.showPasswordField = true;
      this.loginViaPassword = false;
      this.submitText = "Submit";
      // const globalAuthData = this.$store.getters.getGlobalAuthData;
      if (this.getSelectedOrgData) {
        // console.log("getSelectedOrgData", getSelectedOrgData);
        this.selectedOrganization = this.getSelectedOrgData;
        // this.setOrganizationDropDownData(this.getGlobalAuthData);
        this.organizationOptionDisabled = true;
      }
    }
  },
  methods: {
    ...mapActions([
      "fetchPermissions",
      "isLoginViaPassword",
      "setSelectedOrgData",
    ]),

    handleForgotPassword() {
      this.$router.push({
        name: "forgot-password",
        query: {
          request_id: this.getRequestId,
          org_type: this.selectedOrganization?.org_type,
          org_id: this.getOrgId,
          email: this.formData.email,
        },
        params: {
          request_id: this.getRequestId,
          org_type: this.selectedOrganization?.org_type,
          org_id: this.getOrgId,
          email: this.formData.email,
        },
      });
    },

    manageSSoList(data) {
      if (data.authentication_methods.length) {
        this.ssoList = data.authentication_methods.filter(
          (el) => el.auth_type === "external_idp"
        );
      }
    },
    gotToInitialState() {
      this.organizationSelection = true;
      this.loginViaPassword = false;
      this.loginViaSso = false;
      this.loginViaSingleSso = false;
      this.showPasswordField = false;
    },
    ssoLogin(item) {
      console.log("sso login", item);
    },
    handleTogglingField() {
      const authMethods = this.selectedOrganization?.authentication_methods;
      this.showPasswordField = false;
      this.formData.password = "";
      if (authMethods?.length === 1) {
        if (authMethods[0].auth_type === "internal_cognito") {
          this.loginViaPassword = false;
          this.loginViaSso = false;
          this.organizationSelection = false;
          this.loginViaSingleSso = false;
          this.showPasswordField = true;
        }
        if (authMethods[0].auth_type === "external_idp") {
          this.loginViaSingleSso = true;
          this.loginViaPassword = false;
          this.loginViaSso = false;
          this.organizationSelection = false;
          this.showPasswordField = false;
        }
      } else {
        this.loginViaPassword = true;
        this.loginViaSso = true;
        this.organizationSelection = false;
        this.loginViaSingleSso = false;
        this.showPasswordField = false;
      }
      this.getSubmitButtonText();
    },
    getSubmitButtonText() {
      if (this.organizationSelection) {
        this.submitText = "Next";
      } else if (this.loginViaPassword) {
        this.submitText = "Login via password";
      } else if (this.showPasswordField) {
        this.submitText = "Submit";
      } else if (this.loginViaSingleSso) {
        this.submitText = "SSO Login";
      } else {
        this.submitText = "";
      }
    },
    handleOrganizationSelection(data) {
      this.gotToInitialState();
      this.selectedOrganization = {
        ...data,
        name:
          data?.org_type === "TENANT" ? data?.tenant_name : data?.client_name,
        id: data?.user_id,
      };
      if (data.authentication_methods.length > 1) {
        this.manageSSoList(this.selectedOrganization);
      }
      this.getSubmitButtonText();
      this.handleTogglingField();
    },
    isSingleOrganization(globalAuthData) {
      // set request id
      // this.requestId = globalAuthData?.data[0]?.request_id;
      if (Array.isArray(globalAuthData?.data)) {
        this.requestId = globalAuthData?.data[0]?.request_id;
        return globalAuthData?.data?.length === 1;
      } else {
        this.requestId = globalAuthData?.data?.request_id;
        // if response contains object
        return true;
      }
      // return (
      //   Array.isArray(globalAuthData?.data) &&
      //   globalAuthData?.data?.length === 1
      // );
    },
    isMultiOrganization(globalAuthData) {
      return (
        Array.isArray(globalAuthData?.data) && globalAuthData?.data?.length > 1
      );
    },
    isSingleAuth(organizationData) {
      return (
        Array.isArray(organizationData?.authentication_methods) &&
        organizationData?.authentication_methods?.length === 1
      );
    },
    isMultiAuth(organizationData) {
      return (
        Array.isArray(organizationData?.authentication_methods) &&
        organizationData?.authentication_methods?.length > 1
      );
    },
    isRequestPassword(authMethod) {
      return (
        authMethod?.auth_type === "internal_cognito" &&
        authMethod?.["next-state"] === "REQUEST_PASSWORD"
      );
    },
    isRequestSso(authMethod) {
      return (
        authMethod?.auth_type === "external_auth" &&
        authMethod?.["next-state"] === "REQUEST_SSO_REDIRECT"
      );
    },
    setOrganizationDropdownOptions(data) {
      this.organizationOption = data?.data.map((el) => ({
        ...el,
        name: (el.tenant_name && el?.client_name) ? el.tenant_name + '-' + el.client_name : el.tenant_name ? el.tenant_name : el.client_name
      }));
    },
    setOrganizationDropDownData(data) {
      let orgData = Array.isArray(data.data) ? data?.data[0] : data?.data;
      if (orgData) {
        this.selectedOrganization = {
          ...orgData,
          name: (orgData.tenant_name && orgData?.client_name) ? orgData.tenant_name + '-' + orgData.client_name : orgData.tenant_name ? orgData.tenant_name : orgData.client_name
        };
        if (
          !Array.isArray(data.data) &&
          data.data.authentication_methods.length > 1
        ) {
          this.manageSSoList(this.selectedOrganization);
        }
      }
    },
    handleGlobalAuthData() {
      try {
        const globalAuthData = this.getGlobalAuthData;
        if (!globalAuthData) {
          this.$router.push("/login");
          return;
        }
        // check if single organization
        if (this.isSingleOrganization(globalAuthData)) {
          // set the data in drop down
          this.setOrganizationDropDownData(globalAuthData);
          this.organizationOptionDisabled = true;
          // check if single auth
          let orgData = Array.isArray(globalAuthData.data)
            ? globalAuthData?.data[0]
            : globalAuthData?.data;
          if (this.isSingleAuth(globalAuthData?.data[0])) {
            let authMethod = orgData?.authentication_methods[0];
            if (this.isRequestPassword(authMethod)) {
              this.handleTogglingField();
            } else if (this.isRequestSso(authMethod)) {
              this.handleTogglingField();
            }
          } else if (this.isMultiAuth(orgData)) {
            // this.loginViaPassword = true;
            // this.loginViaSso = true;
            this.handleTogglingField();
          }
        } else if (this.isMultiOrganization(globalAuthData)) {
          this.setOrganizationDropdownOptions(globalAuthData);
          this.organizationOptionDisabled = false;
          this.selectedOrganization = null;
          this.loginViaPassword = false;
          this.loginViaSso = false;
          this.organizationSelection = true;
        }
      } catch (error) {
        console.error("error", error);
      }
    },
    async submitForm() {
      switch (this.submitText) {
        case "Next": {
          this.isLoginViaPassword(false);
          this.handleTogglingField();
          break;
        }
        case "Login via password": {
          this.showPasswordField = true;
          this.loginViaPassword = false;
          this.getSubmitButtonText();
          break;
        }
        case "SSO Login": {
          this.isLoginViaPassword(false);
          // login with sso
          this.handleSsoLogin(
            this.selectedOrganization.authentication_methods[0]
          );
          break;
        }
        case "Submit": {
          this.isLoginViaPassword(false);
          // final submit
          this.submitPassword();
          break;
        }
      }
    },
    async handleLoginViaPassword() {
      try {
        this.loading = true;
        this.setSelectedOrgData(this.selectedOrganization);
        let authMethod = this.selectedOrganization?.authentication_methods?.find(
          (m) => m.auth_type === "internal_cognito"
        );
        let payload = {
          email: this.$route?.query?.email,
          org_id: this.getOrgId,
          org_type: this.selectedOrganization?.org_type,
          authentication_method_id: authMethod?.userpool_authentication_method,
        };
        this.$store.dispatch("manageSendOtpData", payload);
        const data = await sendOtp(payload);
        this.loading = false;
        if (data.success) {
          this.$router.push({
            name: "otp-login",
            query: {
              requestId: data?.request_id,
              email: this.$route?.query?.email,
              type: "login-via-password",
            },
          });
        }
      } catch (err) {
        this.loading = false;
        this.$toast.error(err?.response?.data?.detail || "Something went wrong");
        console.error("err", err);
      }
    },
    async submitPassword() {
      try {
        this.loading = true;
        let payload = {
          email: this.formData.email.trim(),
          password: this.formData.password.trim(),
          request_id: this.getRequestId,
          org_type: this.selectedOrganization?.org_type,
          org_id: this.getOrgId,
        };
        const response = await submitPasswordForLogin(payload);
        this.loading = false;
        if (response?.data?.status == true) {
          if (response?.data?.status_code === 1007) {
            this.disabled = false;
            this.$router.push({
              name: "update-password",
              query: {
                requestId: this.getRequestId,
                orgId: this.getOrgId,
                orgType: this.selectedOrganization?.org_type,
                email: this.formData.email.trim(),
                set_type: "New",
              },
            });
          } else {
            VueCookies.set("token", response.data.data.access_token);
            VueCookies.set("refresh_token", response.data.data.refresh_token);
            if (response.data.data.access_token) {
              await this.fetchPermissions();
            }
            // Reset the global auth data after login
            this.$store.dispatch("manageGlobalAuthData", null);
            this.$router.push("/");
          }
        } else {
          this.disabled = false;
          this.showError = true;
          this.$toast.error(response?.data?.message || "Something went wrong!");
        }
      } catch (err) {
        this.disabled = false;
        this.showError = true;
        this.loading = false;
        this.$toast.error(
          err?.response?.data?.detail || "Something went wrong"
        );
        console.log("err", err);
      }
    },
    // handleNextUiForLogin(nextUi, data) {
    //   switch (nextUi) {
    //     case "REQUEST_SSO_REDIRECT": {
    //       this.handleSsoLogin(data);
    //       break;
    //     }
    //     case "REQUEST_PASSWORD": {
    //       this.handlePasswordLogin();
    //       break;
    //     }
    //     case "REQUEST_EMAIL_VERIFICATION_OTP": {
    //       this.handleOtpLogin();
    //       break;
    //     }
    //     case "REQUEST_SELF_REGISTRATION": {
    //       this.handleRequestSelfRegistration();
    //       break;
    //     }
    //     case "ORGANIZATION_SELECTION": {
    //       // this.handleOrganizationSelection();
    //       break;
    //     }
    //     case "AUTHENTICATION_METHOD_SELECTION": {
    //       this.handleAuthenticationMethod();
    //       break;
    //     }
    //   }
    // },
    handleSsoLogin(data) {
      try {
        let payload = {
          org_id:
            data?.org_type === "TENANT" ? data?.tenant_id : data?.client_id,
          org_type: data?.org_type,
          email: this.formData.email,
          authentication_user_id: data?.authentication_user_id,
          authentication_method_id: data?.userpool_authentication_method,
          request_id: this.getRequestId,
        };
        this.$store.dispatch("ssoRedirectLogin", {
          payload,
          userType: "existing",
        });
      } catch (err) {
        console.log("err", err);
      }
    },
    handlePasswordLogin() {
      this.$router.push({
        name: "otp-login",
        query: { requestId: this.requestId },
      });
    },
    handleOtpLogin() {
      this.$router.push({
        name: "otp-login",
        query: { requestId: this.requestId },
      });
    },
    handleRequestSelfRegistration() {},
    // handleOrganizationSelection() {},
    handleAuthenticationMethod() {},

    disableError() {
      this.disabled = false;
    },
    togglePass() {
      this.$refs.passRef.type =
        this.$refs.passRef.type === "text" ? "password" : "text";
      this.isPassVisible = !this.isPassVisible;
    },
    handleInputBlur() {
      // this.$refs.passRef.type = 'password';
    },
    moveToPath() {
      this.$router.push({
        name: "otp-login",
        query: { requestId: this.requestId },
      });
    },
    isValidEmail(email) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    },
  },
  computed: {
    ...mapGetters([
      "getUnifiedPortalState",
      "getLoginViaPassword",
      "getGlobalAuthData",
      "getSelectedOrgData",
    ]),
    getOrgId() {
      const orgId =
        this.selectedOrganization?.org_type === "TENANT"
          ? this.selectedOrganization?.tenant_id
          : this.selectedOrganization?.client_id;

      return orgId;
    },
    getRequestId() {
      return this.$route?.query?.requestId;
    },
    showOrganizationSelection() {
      const globalAuthData = this.$store.getters.getGlobalAuthData;
      return this.isSingleOrganization(globalAuthData) &&
        globalAuthData?.next_ui === "REQUEST_PASSWORD"
        ? false
        : true;
    },
    isFormFilled() {
      if (!this.showPasswordField) {
        return this.formData?.email;
      } else {
        return this.formData?.email && this.formData?.password;
      }
    },
    isEmailValid() {
      return this.isValidEmail(this.formData.email);
    },
    isPassValid() {
      return this.formData?.password.length > 11;
    },
    loginScreenInfo() {
      return sections.login_screen_info;
    },
    poweredByImgUrl() {
      // return sections.login_screen_info.logo_url
      return `data:image/png;base64,${this.loginScreenInfo.logo}`;
    },
  },
};
</script>
<style lang="scss">
.custom-container {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;

  .brand {
    height: 100%;
    background-color: var(--brand-color);
    display: grid;
    place-content: center;
    position: relative;

    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;

      #logo {
        width: 200px;
        height: 72px;
        margin-bottom: 5rem;
      }

      p {
        font-size: 32px;
        font-family: "Montserrat";
        color: white;
        margin: 0.5rem 2rem;
        font-weight: 200;
      }
    }

    #bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #136dd6;
    }
  }

  // .loginImage-cnt {
  //     width: 50%;
  //     height: 100vh;
  //     // overflow: hidden;
  //     display: flex;
  // }
  .form-cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6%;
    background-color: white;
  }
  .logo {
    width: 200px;
    height: 72px;
  }
  .btnDisable {
    background-color: #ddd;
    color: #858585;
  }
  // .rounded{
  //     border-radius: 10px;
  // }
  .form-cnt-div {
    padding: 3rem;
  }
  input {
    font-size: 16px;
    border-radius: 10px;
    // border: 1px solid  #d8dbe0;
  }
  input:focus {
    outline: 1px solid rgb(59, 130, 246);
  }
  input::placeholder {
    // color: #49454F;
  }
  .login-button {
    border-radius: 10px;
    border-width: 0px;
    font-size: 16px;
    // background-color: #0d69d5;
    font-weight: 700;
  }
  .sso-button {
    border-width: 1px;
    font-size: 14px;
    // background-color: #0d69d5;
    font-weight: 500;
  }
  .forget-pass {
    color: rgb(13, 106, 213);
  }
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .auth {
    overflow-y: scroll;
  }
  form {
    max-width: 36rem;
  }
  .hideError {
    visibility: hidden;
  }
  .error {
    position: absolute;
  }
  .loginImg {
    width: 100%;
    height: 100%;
  }
  .tagline {
    font-size: 16px;
    font-weight: 600;
  }
  .poweredBy {
    font-size: 12px;
  }
  .poweredBy_logo {
    max-height: 28px;
    min-height: 20px;
    margin-left: 10px;
  }
  .powered_by_cnt {
    margin-top: 15px;
  }
  .tagline_cnt {
    margin-top: 19px;
  }
  .custom_logo {
    max-height: 130px;
    min-height: 72px;
  }
}
</style>
